<template>
  <div class="workStation">
    <Timeline>
      <TimelineItem>
        <p class="time">2021-11-29</p>
        <ul class="content">
          <li>修复保单、专家、项目导入时上传按钮显示错误问题，增加文件解析错误时的提醒！导入失败后会显示失败的数据！</li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-11-25</p>
        <ul class="content">
          <li>基础设置里增加全局配置菜单。
            <router-link to="/main/base/set">[前往查看]</router-link>
          </li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-11-23</p>
        <ul class="content">
          <li>专家库新增修改专家功能完善。
            <router-link to="/main/experts/list">[前往查看]</router-link>
          </li>
          <li>保单管理增加修改保单功能完善。
            <router-link to="/main/policy/list">[前往查看]</router-link>
          </li>
          <li>项目查看详情完善详细信息，右侧增加地图显示。
            <router-link to="/main/projects/list">[前往查看]</router-link>
          </li>
          <li>项目修改的坐标拾取改为腾讯地图。
            <router-link to="/main/projects/list">[前往查看]</router-link>
          </li>
          <li>项目查看图片新增大图预览/轮播。
            <router-link to="/main/projects/list">[前往查看]</router-link>
          </li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-11-22</p>
        <ul class="content">
          <li>专家库新增修改专家功能。。
            <router-link to="/main/experts/list">[前往查看]</router-link>
          </li>
          <li>保单管理增加修改保单功能。
            <router-link to="/main/policy/list">[前往查看]</router-link>
          </li>
          <li>项目修改增加坐标拾取功能。
            <router-link to="/main/projects/list">[前往查看]</router-link>
          </li>
          <li>项目修改增加施工许可证照片、平面图、鸟瞰图。
            <router-link to="/main/projects/list">[前往查看]</router-link>
          </li>
          <li>重新规划上传组件功能。</li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-11-09</p>
        <ul class="content">
          <li>优化表格列表页的数据展示性能。</li>
          <li>新增专家库。
            <router-link to="/main/experts/list">[前往查看]</router-link>
          </li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-11-08</p>
        <ul class="content">
          <li>定时任务新增查看详情页面。</li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-11-02</p>
        <ul class="content">
          <li>调整数据字典页面交互，可以批量修改字典项。
            <router-link to="/main/base/baseData">[前往查看]</router-link>
          </li>
          <li>机构新增和修改页面，增加上级机构。
            <router-link to="/main/org/list">[前往查看]</router-link>
          </li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-11-01</p>
        <ul class="content">
          <li>项目列表新增所在区域。</li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-10-29</p>
        <ul class="content">
          <li>系统设置-数据字典，项新增查看和修改功能；
            <router-link to="/main/base/baseData">[前往查看]</router-link>
          </li>
          <li>项目查询地区选择条件改为强制，可定位到某一行政级别；</li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-10-19</p>
        <ul class="content">
          <li>新增删除保单功能；
            <router-link to="/main/policy/list">[前往查看]</router-link>
          </li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-10-18</p>
        <ul class="content">
          <li>菜单管理删除索引名称改为自动生成，索引层级改为自动生成，可修改；
            <router-link to="/main/base/menu">[前往查看]</router-link>
          </li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-10-11</p>
        <ul class="content">
          <li>修复地区选择组件修改时市/区不显示的问题；</li>
          <li>批量抓取全国行政区域信息，具体到街道级别，但暂不启用；</li>
          <li>修复部分菜单切换动画失效问题；</li>
          <li>调整系统配置主题颜色显示方式，更直观；</li>
          <li>系统配置新增角色管理模块；
            <router-link to="/main/base/role">[前往查看]</router-link>
          </li>
          <li>调整新增机构成员，新增修改时可以选择所属机构；
            <router-link to="/main/org/member">[前往查看]</router-link>
          </li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-10-09</p>
        <ul class="content">
          <li>查看详情基本信息页面的组件重构，页面会更灵活，功能更全。顶部4个基本信息改为一行显示，增加主体部门显示区域；</li>
          <li>修复详情页面分割器在深色模式下颜色显示错误的问题；</li>
          <li>系统设置新增3中主题颜色；</li>
          <li>修复切换菜单折叠状态页面卡顿的问题；</li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-10-08</p>
        <ul class="content">
          <li>项目库新增删除功能；
            <router-link to="/main/projects/list">[前往查看]</router-link>
          </li>
          <li>调整系统配置-定时任务修改界面；
            <router-link to="/main/base/autoTask">[前往查看]</router-link>
          </li>
          <li>任务管理隐患列表的详情页调整，现在可以查看该隐患点回复信息；
            <router-link to="/main/task/problems">[前往查看]</router-link>
          </li>
          <li>任务管理下新增整改单列表模块，展示所有整改单；
            <router-link to="/main/task/amend">[前往查看]</router-link>
          </li>
          <li>大部分查看详情页面可以拖拽2边大小功能；</li>
        </ul>
      </TimelineItem>
      <TimelineItem>
        <p class="time">2021-10-08</p>
        <p class="content">开始记录每天的更新功能。 </p>
      </TimelineItem>
    </Timeline>
  </div>
</template>
<script>
export default {
  name: "index"
}
</script>
<style scoped lang="less">
.workStation {
  padding: 20px;

  .time {
    font-size: 14px;
    font-weight: bold;
  }

  .content {
    counter-reset: show-list;

    li {
      line-height: 22px;
      position: relative;
      padding-left: 25px;
      list-style: none;
      counter-increment: item;

      &:before {
        content: counter(item) ".";
        display: block;
        position: absolute;
        left: 12px;
        top: 0;
      }
    }
  }
}
</style>